/* eslint-disable react/jsx-key */
import { FC } from 'react'
import 'keen-slider/keen-slider.min.css'
import CollectionCard from '@components/common/CollectionCard'
import cn from 'classnames'

interface ShopTheRangesProps {
  primary: {
    block_title?: string
    subtitle?: string
    link_text?: string
    link?: string
    paddingtop?: boolean
    marginbottom?: string
  }
  items: [] | any
}

const ShopTheRanges: FC<ShopTheRangesProps> = ({ primary, items }) => {
  const { block_title, paddingtop, marginbottom } = primary || {}

  const marginBottom = marginbottom || '32'

  return (
    <div
      className={cn('pb-40 tablet:pb-80', {
        'pt-40 laptop:pt-80': paddingtop,
      })}
    >
      <div className="wrapper overflow-hidden">
        <div className="block mb-32 tablet:flex justify-center tablet:mb-0">
          <h2 className="heading-3 mb-28 text-center tablet:mb-40 tablet:heading-4">{block_title}</h2>
        </div>

        <div
          className={cn('block tablet:grid grid-cols-3 ', {
            'gap-x-16 gap-y-32': marginbottom === '32',
            'gap-16': marginbottom === '16',
          })}
        >
          {items &&
            items?.map((item: any, index: number) => {
              const {
                collection_image,
                collection_title,
                collection_icon,
                collection_description,
                tags,
                collection_link,
              } = item || {}
              const newTag = tags?.split(',')?.filter((item: any) => item === 'new')
              const limitedEditionTag = tags?.split(',').find((item: any) => item === 'limited edition')
              const saleTag = tags?.split(',').find((item: any) => item.includes('sale'))
              const salePercentage = saleTag?.split('-')
              return (
                <div
                  key={index}
                  className={cn({
                    'mb-24 tablet:-mb-4': index !== items?.length - 1 && marginbottom === '32',
                    'mb-12 tablet:-mb-4': index !== items?.length - 1 && marginbottom === '16',
                  })}
                >
                  <CollectionCard
                    collectionImage={collection_image}
                    newTag={newTag}
                    limitedEditionTag={limitedEditionTag}
                    saleTag={saleTag}
                    salePercentage={salePercentage}
                    collectionIcon={collection_icon}
                    collectionTitle={collection_title}
                    collectionDescription={collection_description}
                    collectionLink={collection_link}
                  />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default ShopTheRanges
